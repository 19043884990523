<template>
	<div ref="echartBar" style="width:100%;height:100%;">
	</div>
</template>
<script>
	import echarts from 'echarts'
	export default {
		name: 'echartBar',
		props: {
			echartBarData: {
				type: Object,
				default: {}
			},
			legendData: {
				type: Array,
				default: []
			},
			barXAxisData: {
				type: Array,
				default: []
			},
		},
		data() {
			return {}
		},
		mounted() {
			this.getEchart();
			window.addEventListener('resize', this.resize)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.resize)
		},
		watch: {
			echartBarData(n, o) {
				this.$nextTick(() => {
					this.getEchart();
				});
			},
			legendData(n, o) {
				this.$nextTick(() => {
					this.getEchart();
				});
			},
		},
		methods: {
			resize(){
				this.myChart.resize()
			},
			getEchart() {
				this.myChart = echarts.init(this.$refs.echartBar)
				let option = {
					tooltip: {
						trigger: 'axis',
						axisPointer: { // 坐标轴指示器，坐标轴触发有效
							type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
						}
					},
					legend: {
						data: this.legendData
					},
					grid: {
						left: '50px',
						top: '15%',
						right: '3%',
						bottom: '4%',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						data: this.barXAxisData
					},
					yAxis: {
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed'
							}
						},
						type: 'value',
					},
					series: [{
							name: this.legendData[0],
							type: 'bar',
							stack: '总量',
							barWidth: 30,
							itemStyle: {
								normal: {
									color: '#60acfc',
								}
							},
							label: {
								normal: {
									show: true,
									position: 'insideRight'
								}
							},
							z: 10,
							data: this.echartBarData.newValue
						},
						{
							name: this.legendData[1],
							type: 'bar',
							stack: '总量',
							barWidth: 30,
							itemStyle: {
								normal: {
									color: '#feb64d',
								}
							},
							label: {
								normal: {
									show: true,
									position: 'insideRight'
								}
							},
							z: 5,
							data: this.echartBarData.oldValue
						},
					]
				};
				this.myChart.setOption(option);
			}
		}
	}
</script>
