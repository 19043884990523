<template>
	<div class="main-container">
		<statistics-time :unShowType='unShowType' :unIndex='1' @getRangeTime='getRangeTime'></statistics-time>

    <!-- 客户概况及趋势 -->
    <div class="trend module">
      <div class="title"> 
        客户概况及趋势
        <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" placement="top-start">
          <div v-html='toBreak(tooltip.trend)' slot='content'></div>
          <i class="el-icon-warning icon"></i>
        </el-tooltip>
      </div>
      <div class="interview">
        <div class="item">
          <span class="lable">访客数</span>
          <!-- <i class="el-icon-warning icon"></i> -->
          <br>
          <span class="num">{{customerData.VisitCount}}</span>
          <div class="compare" >
            较前一{{currentKeyword}}
						<svg-icon :icon-class="commentArrowsfun(customerData.VisitCount,customerData.LastVisitCount)"></svg-icon>
            <span>{{commentDatafun(customerData.VisitCount,customerData.LastVisitCount)}}%</span>
          </div>
        </div>
        <div class="item">
          <span class="lable">累积粉丝数</span>
          <!-- <i class="el-icon-warning icon"></i> -->
          <br>
          <span class="num">{{customerData.AllWeixinFansCount}}</span>
          <div class="compare" >
            较前一{{currentKeyword}}
						<svg-icon :icon-class="commentArrowsfun(customerData.AllWeixinFansCount,customerData.LastAllWeixinFansCount)"></svg-icon>
            <span>{{commentDatafun(customerData.AllWeixinFansCount,customerData.LastAllWeixinFansCount)}}%</span>
          </div>
        </div>
        <div class="item" >
          <span class="lable">累积会员数</span>
          <!-- <i class="el-icon-warning icon"></i> -->
          <br>
          <span class="num">{{customerData.AllVipCount}}</span>
          <div class="compare" >
            较前一{{currentKeyword}} 
						<svg-icon :icon-class="commentArrowsfun(customerData.AllVipCount,customerData.LastAllVipCount)"></svg-icon>
            <span>{{commentDatafun(customerData.AllVipCount,customerData.LastAllVipCount)}}%</span>
          </div>
        </div>
        <div class="item">
          <span class="lable">成交客户数</span>
          <!-- <i class="el-icon-warning icon"></i> -->
          <br>
          <span class="num">{{customerData.SubmitOrderMemberCount}}</span>
          <div class="compare" >
            较前一{{currentKeyword}} 
						<svg-icon :icon-class="commentArrowsfun(customerData.AllWeixinFansCount,customerData.LastAllWeixinFansCount)"></svg-icon>
            <span>{{commentDatafun(customerData.AllWeixinFansCount,customerData.LastAllWeixinFansCount)}}%</span>
          </div>
        </div>
      </div>
      <div class="echart-box">
        <echart-line :echartLineData='trendLineData' :lineXAxisData='trendLineXAxis' :lineDateData='lineDateData'></echart-line>
      </div>
    </div>

    <!-- 粉丝统计 -->
    <div class="wx-fans module">
      <div class="title"> 
        微信粉丝统计
        <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" placement="top-start">
          <div v-html='toBreak(tooltip.wFans)' slot='content'></div>
          <i class="el-icon-warning icon"></i>
        </el-tooltip>
      </div>
      <div class="interview">
        <div class="item">
          <span class="lable">累积粉丝数</span>
          <!-- <i class="el-icon-warning icon"></i> -->
          <br>
          <span class="num">{{customerData.AllWeixinFansCount}}</span>
          <div class="compare" >
            较前一{{currentKeyword}} 
						<svg-icon :icon-class="commentArrowsfun(customerData.AllWeixinFansCount,customerData.LastAllWeixinFansCount)"></svg-icon>
            <span>{{commentDatafun(customerData.AllWeixinFansCount,customerData.LastAllWeixinFansCount)}}%</span>
          </div>
        </div>
        <div class="item">
          <span class="lable">新增粉丝数</span>
          <!-- <i class="el-icon-warning icon"></i> -->
          <br>
          <span class="num">{{customerData.NewWeixinFansCount}}</span>
          <div class="compare" >
            较前一{{currentKeyword}} 
						<svg-icon :icon-class="commentArrowsfun(customerData.NewWeixinFansCount,customerData.LastNewWeixinFansCount)"></svg-icon>
            <span>{{commentDatafun(customerData.NewWeixinFansCount,customerData.LastNewWeixinFansCount)}}%</span>
          </div>
        </div>
        <div class="item" >
          <span class="lable">跑路粉丝数</span>
          <!-- <i class="el-icon-warning icon"></i> -->
          <br>
          <span class="num">{{customerData.RunawayWeixinFansCount}}</span>
          <div class="compare" >
            较前一{{currentKeyword}} 
						<svg-icon :icon-class="commentArrowsfun(customerData.RunawayWeixinFansCount,customerData.LastRunawayWeixinFansCount)"></svg-icon>
            <span>{{commentDatafun(customerData.RunawayWeixinFansCount,customerData.LastRunawayWeixinFansCount)}}%</span>
          </div>
        </div>
        <div class="item">
          <span class="lable">净增粉丝数</span>
          <!-- <i class="el-icon-warning icon"></i> -->
          <br>
          <span class="num">{{customerData.NetIncrease}}</span>
          <div class="compare" >
            较前一{{currentKeyword}} 
						<svg-icon :icon-class="commentArrowsfun(customerData.NetIncrease,customerData.LastNetIncrease)"></svg-icon>
            <span>{{commentDatafun(customerData.NetIncrease,customerData.LastNetIncrease)}}%</span>
          </div>
        </div>
      </div>

      <!-- 粉丝活跃 -->
      <div class="secondary-title"> 
        粉丝活跃
      </div>
       <div class="interview">
        <div class="item">
          <span class="lable">访问粉丝数</span>
          <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" placement="top-start">
            <div v-html='toBreak(tooltip.VisitWeixinFansCount)' slot='content'></div>
            <i class="el-icon-warning icon"></i>
          </el-tooltip>
          <br>
          <span class="num">{{customerData.VisitWeixinFansCount}}</span>
          <div class="compare" >
            占比
            <span>{{commentProportion(customerData.VisitWeixinFansCount,customerData.AllWeixinFansCount)}}%</span>
          </div>
        </div>
        <div class="item">
          <span class="lable">领券粉丝数</span>
          <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" placement="top-start">
            <div v-html='toBreak(tooltip.CollectCouponWeixinFansCount)' slot='content'></div>
            <i class="el-icon-warning icon"></i>
          </el-tooltip>
          <br>
          <span class="num">{{customerData.CollectCouponWeixinFansCount}}</span>
          <div class="compare" >
            占比
            <span>{{commentProportion(customerData.CollectCouponWeixinFansCount,customerData.AllWeixinFansCount)}}%</span>
          </div>
        </div>
        <div class="item" >
          <span class="lable">加购粉丝数</span>
          <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" placement="top-start">
            <div v-html='toBreak(tooltip.AddToCartWeixinFansCount)' slot='content'></div>
            <i class="el-icon-warning icon"></i>
          </el-tooltip>
          <br>
          <span class="num">{{customerData.AddToCartWeixinFansCount}}</span>
          <div class="compare" >
            占比
            <span>{{commentProportion(customerData.AddToCartWeixinFansCount,customerData.AllWeixinFansCount)}}%</span>
          </div>
        </div>
        <div class="item">
          <span class="lable">成交粉丝数</span>
          <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" placement="top-start">
            <div v-html='toBreak(tooltip.PayOrderWeixinFansCount)' slot='content'></div>
            <i class="el-icon-warning icon"></i>
          </el-tooltip>
          <br>
          <span class="num">{{customerData.PayOrderWeixinFansCount}}</span>
          <div class="compare" >
            占比
            <span>{{commentProportion(customerData.PayOrderWeixinFansCount,customerData.AllWeixinFansCount)}}%</span>
          </div>
        </div>
      </div>
      <div class="echart-box">
        <echart-line :echartLineData='fansLineData' :lineXAxisData='fansLineXAxis' :lineDateData='lineDateData'></echart-line>
      </div>
    </div>

     <!-- 会员统计 -->
    <div class="vip-statistics module">
      <div class="title"> 
        会员统计
        <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" placement="top-start">
          <div v-html='toBreak(tooltip.AllVipStatistics)' slot='content'></div>
          <i class="el-icon-warning icon"></i>
        </el-tooltip>
      </div>
      <div class="interview">
        <div class="item">
          <span class="lable">累积会员数</span>
          <!-- <i class="el-icon-warning icon"></i> -->
          <br>
          <span class="num">{{customerData.AllVipCount}}</span>
          <div class="compare" >
            较前一{{currentKeyword}} 
						<svg-icon :icon-class="commentArrowsfun(customerData.AllVipCount,customerData.LastAllVipCount)"></svg-icon>
            <span>{{commentDatafun(customerData.AllVipCount,customerData.LastAllVipCount)}}%</span>
          </div>
        </div>
        <div class="item">
          <span class="lable">新增会员数</span>
          <br>
          <span class="num">{{customerData.NewVipCount}}</span>
          <div class="compare" >
            较前一{{currentKeyword}} 
						<svg-icon :icon-class="commentArrowsfun(customerData.NewVipCount,customerData.LastNewVipCount)"></svg-icon>
            <span>{{commentDatafun(customerData.NewVipCount,customerData.LastNewVipCount)}}%</span>
          </div>
        </div>
        <div class="item" >
          <span class="lable">升级会员数</span>
          <br>
          <span class="num">{{customerData.UpVipCount}}</span>
          <div class="compare" >
            较前一{{currentKeyword}} 
						<svg-icon :icon-class="commentArrowsfun(customerData.UpVipCount,customerData.LastUpVipCount)"></svg-icon>
            <span>{{commentDatafun(customerData.UpVipCount,customerData.LastUpVipCount)}}%</span>
          </div>
        </div>
        <!-- <div class="item">
          <span class="lable">储值会员数</span>
          <br>
          <span class="num">6440</span>
          <div class="compare" >
            较前一{{currentKeyword}} 
            <svg-icon icon-class="gorise"></svg-icon> 
            12.46%
          </div>
        </div> -->
      </div>
      <!-- 会员统计 -->
      <div class="secondary-title"> 
        会员活跃
      </div>
       <div class="interview">
        <div class="item">
          <span class="lable">访问会员数</span>
          <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" placement="top-start">
            <div v-html='toBreak(tooltip.VisitVipCount)' slot='content'></div>
            <i class="el-icon-warning icon"></i>
          </el-tooltip>
          <br>
          <span class="num">{{customerData.VisitVipCount}}</span>
          <div class="compare" >
            占比
            <span>{{commentProportion(customerData.VisitVipCount,customerData.AllVipCount)}}%</span>
          </div>
        </div>
        <div class="item">
          <span class="lable">领券会员数</span>
          <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" placement="top-start">
            <div v-html='toBreak(tooltip.CollectCouponVipCount)' slot='content'></div>
            <i class="el-icon-warning icon"></i>
          </el-tooltip>
          <br>
          <span class="num">{{customerData.CollectCouponVipCount}}</span>
          <div class="compare" >
            占比
            <span>{{commentProportion(customerData.CollectCouponVipCount,customerData.AllVipCount)}}%</span>
          </div>
        </div>
        <div class="item" >
          <span class="lable">加购会员数</span>
          <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" placement="top-start">
            <div v-html='toBreak(tooltip.AddToCartVipCount)' slot='content'></div>
            <i class="el-icon-warning icon"></i>
          </el-tooltip>
          <br>
          <span class="num">{{customerData.AddToCartVipCount}}</span>
          <div class="compare" >
            占比
            <span>{{commentProportion(customerData.AddToCartVipCount,customerData.AllVipCount)}}%</span>
          </div>
        </div>
        <div class="item">
          <span class="lable">成交会员数</span>
          <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" placement="top-start">
            <div v-html='toBreak(tooltip.PayOrderVipCount)' slot='content'></div>
            <i class="el-icon-warning icon"></i>
          </el-tooltip>
          <br>
          <span class="num">{{customerData.PayOrderVipCount}}</span>
          <div class="compare" >
            占比
            <span>{{commentProportion(customerData.PayOrderVipCount,customerData.AllVipCount)}}%</span>
          </div>
        </div>
      </div>
      <div class="echart-box">
        <echart-line :echartLineData='vipLineData' :lineXAxisData='vipLineXAxis' :lineDateData='lineDateData'></echart-line>
      </div>
    </div>

    <!-- 成交客户分析 -->
    <div class="closed-customer module">
      <div class="title"> 
        成交客户分析
        <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" placement="top-start">
          <div v-html='toBreak(tooltip.DealCustomer)' slot='content'></div>
          <i class="el-icon-warning icon"></i>
        </el-tooltip>
      </div>
      <div class="table">
        <el-table :data="tableList1">
          <el-table-column label="客户类型" prop="CustomerTypeValue"></el-table-column>
          <el-table-column label="客户数" prop="">
            <template slot-scope="scope">
              <span style="margin-right:10px;">
                {{scope.row.PayOrderMemberCount}}
              </span>
              <span>
                <svg-icon :icon-class="commentArrowsfun(scope.row.PayOrderMemberCount,scope.row.beforePayOrderMemberCount)"></svg-icon>
                <span>{{commentDatafun(scope.row.PayOrderMemberCount,scope.row.beforePayOrderMemberCount)}}%</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column  prop="">
            <template slot="header">
              <label>客户数占比</label>
              <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" placement="top-start">
                <div v-html='toBreak(tooltip.CustomerRate)' slot='content'></div>
                <i class="el-icon-warning icon"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <span style="margin-right:10px;">
                {{scope.row.CustomerRate}}%
              </span>
              <span>
                <svg-icon :icon-class="commentArrowsfun(scope.row.CustomerRate,scope.row.beforeCustomerRate)"></svg-icon>
                <span>{{commentRateTrend(scope.row.CustomerRate,scope.row.beforeCustomerRate)}}%</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column >
            <template slot="header">
              <label>客单价</label>
              <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" :content='tooltip.UnitCustomerPrice' effect="light" placement="top-start">
                <i class="el-icon-warning icon"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <span style="margin-right:10px;">
                {{scope.row.UnitCustomerPrice}}
              </span>
              <span>
                <svg-icon :icon-class="commentArrowsfun(scope.row.UnitCustomerPrice,scope.row.beforeUnitCustomerPrice)"></svg-icon>
                <span>{{commentDatafun(scope.row.UnitCustomerPrice,scope.row.beforeUnitCustomerPrice)}}%</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="付款金额" prop="">
            <template slot-scope="scope">
              <span style="margin-right:10px;">
                {{scope.row.PayOrderMoney}}
              </span>
              <span>
                <svg-icon :icon-class="commentArrowsfun(scope.row.PayOrderMoney,scope.row.beforePayOrderMoney)"></svg-icon>
                <span>{{commentDatafun(scope.row.PayOrderMoney,scope.row.beforePayOrderMoney)}}%</span>
              </span>
            </template>
          </el-table-column>
          <!-- 先注释 -->
          <!-- <el-table-column label="访问-付款转化率" prop="count">
            <template slot-scope="scope">
              <span style="margin-right:10px;">
                {{scope.row.Uv}}
              </span>
              <span>
                <svg-icon :icon-class="commentArrowsfun(scope.row.Uv,scope.row.beforeUv)"></svg-icon>
                <span>{{commentDatafun(scope.row.Uv,scope.row.beforeUv)}}%</span>
              </span>
            </template>
          </el-table-column> -->
        </el-table>
        <el-pagination
          style="margin-top:20px;float:right;"
          v-if="page.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="page.size"
          :total="page.total"
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
      <div class="secondary-title" style="margin-top:30px;">
        <span> 趋势指标选择</span>
        <el-radio-group style="margin-left:20px;" v-model="trendRadioVisit"  @change='handleTrendRadio'>
          <el-radio label="PayOrderMemberCount">客户数</el-radio>
          <el-radio label="UnitCustomerPrice">客单价</el-radio>
          <el-radio label="PayOrderMoney">付款金额</el-radio>
           <!-- 先注释 -->
          <!-- <el-radio label="CustomerRate">访问-付款转化率</el-radio> -->
        </el-radio-group>
        <div class="echart-box bar">
          <echart-bar :echartBarData='trendBarData' :barXAxisData='trendXAxisData' :legendData='trendLegend' ></echart-bar>
        </div>
      </div>

      <!-- 会员粉丝成交 -->
      <div class="secondary-title">
        会员粉丝成交
        <div class="echart-box bar">
          <echart-bar :echartBarData='vipFansBarData' :barXAxisData='vipFansXAxisData' :legendData='vipFansLegend'></echart-bar>
        </div>
      </div>

      <!-- 老成交客户趋势 -->
      <div class="secondary-title">
        老成交客户趋势
        <div class="echart-box">
          <echart-line :echartLineData='oldLineData' :lineXAxisData='oldLineXAxis'></echart-line>
        </div>
      </div>
    </div>

    <!-- 客户留存分析 -->
    <div class="retention module">
      <div class="title">
        <span style="float:left;margin-top: 10px;">客户留存分析</span>
        <statistics-time style="display:inline-block;float:right;" :titleShow='false' :defaultType='8' :unShowType='unShowType2' @getRangeTime='getRangeTime2'></statistics-time>
      </div>
      <el-tabs v-model="listState" type="card" @tab-click="handleTabClick">
				<el-tab-pane v-for="item in listStateList" :key="item.id" :label="item.value" :name="item.id"></el-tab-pane>
			</el-tabs>
      <!-- 新访问留存 -->
      <div class="table" v-show="listState==1">
        <!-- :row-class-name="tableRowClassName" -->
        <el-table border :data="tableList2"  :cell-class-name="cellStyle" :loading='tableLoading'>
          <el-table-column :key="1" label="访问时间" prop="StatisDateStr"></el-table-column>
          <el-table-column :key="2" label="新访问客户数" prop="MemberCount"></el-table-column>
          <el-table-column :key="3" label="第1月留存" prop="One" v-if="timeType2==8||timeType2==9">
            <template slot-scope="scope">
              <div v-if="scope.row.One!=0">
                <div>{{scope.row.One}}</div>
                <div>{{scope.row.OneRate}}%</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column :key="4" label="第2月留存" prop="Two" v-if="timeType2==9||timeType2==8">
            <template slot-scope="scope">
              <div v-if="scope.row.Two!=0">
                <div>{{scope.row.Two}}</div>
                <div>{{scope.row.TwoRate}}%</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column :key="5" label="第3月留存" prop="Three" v-if="timeType2==9">
            <template slot-scope="scope">
              <div v-if="scope.row.Three!=0">
                <div>{{scope.row.Three}}</div>
                <div>{{scope.row.ThreeRate}}%</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column :key="6" label="第4月留存" prop="Four" v-if="timeType2==9">
            <template slot-scope="scope">
              <div v-if="scope.row.Four!=0">
                <div>{{scope.row.Four}}</div>
                <div>{{scope.row.FourRate}}%</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column :key="7" label="第5月留存" prop="Five" v-if="timeType2==9">
            <template slot-scope="scope">
              <div v-if="scope.row.Five!=0">
                <div>{{scope.row.Five}}</div>
                <div>{{scope.row.FiveRate}}%</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin-top:20px;float:right;"
          v-if="viPage.total"
          @size-change="handleViSizeChange"
          @current-change="handleViCurrentChange"
          :current-page="viPage.current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="viPage.size"
          :total="viPage.total"
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
      <!-- 新成交留存 -->
      <div class="table"  v-show="listState==2">
        <!-- :row-class-name="tableRowClassName" -->
        <el-table border :data="tableList2"  :cell-class-name="cellStyle" :loading='tableLoading'>
          <el-table-column :key="8" label="成交时间" prop="StatisDateStr"></el-table-column>
          <el-table-column :key="9" label="新成交客户数" prop="MemberCount"></el-table-column>
          <el-table-column :key="10" label="第1月留存" prop="One" v-if="timeType2==8||timeType2==9">
            <template slot-scope="scope">
              <div v-if="scope.row.One!=0">
                <div>{{scope.row.One}}</div>
                <div>{{scope.row.OneRate}}%</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column :key="11" label="第2月留存" prop="Two" v-if="timeType2==9||timeType2==8">
            <template slot-scope="scope">
              <div v-if="scope.row.Two!=0">
                <div>{{scope.row.Two}}</div>
                <div>{{scope.row.TwoRate}}%</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column :key="12" label="第3月留存" prop="Three" v-if="timeType2==9">
            <template slot-scope="scope">
              <div v-if="scope.row.Three!=0">
                <div>{{scope.row.Three}}</div>
                <div>{{scope.row.ThreeRate}}%</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column :key="13" label="第4月留存" prop="Four" v-if="timeType2==9">
            <template slot-scope="scope">
              <div v-if="scope.row.Four!=0">
                <div>{{scope.row.Four}}</div>
                <div>{{scope.row.FourRate}}%</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column :key="14" label="第5月留存" prop="Five" v-if="timeType2==9">
            <template slot-scope="scope">
              <div v-if="scope.row.Five!=0">
                <div>{{scope.row.Five}}</div>
                <div>{{scope.row.FiveRate}}%</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin-top:20px;float:right;"
          v-if="rePage.total"
          @size-change="handleReSizeChange"
          @current-change="handleReCurrentChange"
          :current-page="rePage.current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="rePage.size"
          :total="rePage.total"
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import statisticsTime from '../StatisticsTime'
import config from '@/config/index'
import echartLine from './echartLine'
import echartBar from './echartBar'
import { get } from 'http'
import apiList from '@/api/other'
export default {
  components:{statisticsTime, echartLine, echartBar},
  data(){
    return {
      tooltip: {
        trend: "访客数：统计筛选时间内，所有商城页面被访问的人数，人数去重，1人访问多次计1人\n累积粉丝数： 截至到筛选时间的最后一天，关注公众号的粉丝数\n累积会员数：截至到筛选时间的最后一天， 商城会员人数\n成交客户数：统计筛选时间内，支付成功的人数，1人支付成功多次，计1人",
        wFans: '累积粉丝数：截至到筛选时间的最后一天，关注公众号的粉丝数\n新增粉丝数： 统计筛选时间内，新关注的粉丝去重人数\n跑路粉丝数：统计筛选时间内，取消关注的粉丝去重人数\n净增粉丝数：统计筛选时间内，实际增长的粉丝人数（即：新增粉丝数减去跑路粉丝）',
        
        VisitWeixinFansCount: '访问粉丝数：统计筛选时间内，访问过店铺，并且是公众号粉丝的人数，1人多次访问计1人\n访问粉丝数占比： 统计筛选时间内，访问粉丝数 / 累积粉丝数',
        CollectCouponWeixinFansCount: '领券粉丝数：统计筛选时间内，领取了优惠券，并且关注公众号的人数，1人领取多次计1人\n领取粉丝数占比： 统计筛选时间内，领券粉丝数 / 累积粉丝数',
        AddToCartWeixinFansCount: '加购粉丝数：统计筛选时间内，添加商品至购物车，并且关注公众号的人数，1人添加多次计1人\n加购粉丝数占比： 统计筛选时间内，加购粉丝数 / 累积粉丝数',
        PayOrderWeixinFansCount: '成交粉丝数：统计筛选时间内，支付成功，并且关注公众号的人数，1人添加多次计1人\n成交粉丝数占比： 统计筛选时间内，成交粉丝数 / 累积粉丝数',
        
        AllVipStatistics:'累积会员数：截至到筛选时间的最后一天，是会员的人数\n新增会员数： 统计筛选时间内，成为会员的人数\n升级会员数：统计筛选时间内，会员等级提升的人数',

        VisitVipCount: '访问会员数：统计筛选时间内，访问过店铺，并且是会员的人数，1人多次访问计1人\n访问会员数占比： 统计筛选时间内，访问会员数 / 累积会员数',
        CollectCouponVipCount: '领券会员数：统计筛选时间内，领取了优惠券，并且是会员的人数，1人领取多次计1人\n领取会员数占比： 统计筛选时间内，领券会员数 / 累积会员数',
        AddToCartVipCount: '加购会员数：统计筛选时间内，添加商品至购物车，并且是会员的人数，1人添加多次计1人\n加购会员数占比： 统计筛选时间内，加购会员数 / 累积会员数',
        PayOrderVipCount: '成交会员数：统计筛选时间内，支付成功，并且是会员的人数，1人添加多次计1人\n成交会员数占比： 统计筛选时间内，成交会员数 / 累积会员数',

        DealCustomer: '新成交客户：在筛选时间之前没有支付成功过订单，在筛选时间内支付成功订单的客户\n老成交客户：在筛选时间之前有支付成功过订单，在筛选时间内再次支付成功订单的客户',
        CustomerRate: '全部成交客户占比：统计筛选时间内，成交客户数 / 累积所有客户数\n新成交客户占比：统计筛选时间内，新成交客户数 / 全部成交客户数\n老成交客户占比：统计筛选时间内，老成交客户数 / 全部成交客户数',
        UnitCustomerPrice: '统计筛选时间内，支付金额/支付人数',
      },
      customerData:{},
      unShowType:[0,6,7,8,9],
      currentKeyword:'日',
      timeKeywords:['日','周','月','天','天'],
      startDate:'',
      endDate:'',
      startLineTime:'',
      endLineTime:'',
      unShowType2:[0,1,2,3,4,5,6],
      // currentKeyword2:'日',
      startDate2:'',
      endDate2:'',
      timeType2:1,
      trendLineXAxis:[],
      trendLineData:[
        {
          name: '访客数',
          value: [],
          label:'VisitCount'
        },
        {
          name: '累积粉丝数',
          value: [],
          label:'AllWeixinFansCount' 
        },
        {
          name: '累积会员数',
          value: [],
          label:'AllVipCount' 
        },
        {
          name: '成交客户数',
          value: [],
          label:'SubmitOrderMemberCount' 
        }
      ],
      fansLineXAxis:[],
      fansLineData:[
        {
          name: '访问粉丝数',
          value: [],
          label:'VisitWeixinFansCount' 
        },
        {
          name: '领券粉丝数',
          value: [],
          label:'CollectCouponWeixinFansCount' 
        },
        {
          name: '加购粉丝数',
          value: [],
          label:'AddToCartWeixinFansCount' 
        },
        {
          name: '成交粉丝数',
          value: [],
          label:'PayOrderWeixinFansCount' 
        }
      ],
      vipLineXAxis:[],
      vipLineData:[
        {
          name: '访问会员数',
          value: [],
          label:'VisitVipCount' 
        },
        {
          name: '领券会员数',
          value: [],
          label:'CollectCouponVipCount' 
        },
        {
          name: '加购会员数',
          value: [],
          label:'AddToCartVipCount' 
        },
        {
          name: '成交会员数',
          value: [],
          label:'PayOrderVipCount' 
        }
      ],
      lineDateData:[],
      tableList:[],
      tableLoading:false,
      page:{
        size:20,
        total:0,
        current:1
      },
      tradingAnalyzeData:{},
      tableList1:[],
      trendLegend: ['新成交客户数', '老成交客户数'],
      trendXAxisData:[],
      trendBarData:{
        newValue: [],
        oldValue: [],
      },
      trendRadioVisit:'PayOrderMemberCount',
      trendRadioData:[
        {
          name: '客户数',
          newValue: [],
          oldValue: [],
          label:'PayOrderMemberCount' 
        },
        {
          name: '客单价',
          newValue: [],
          OldValue: [],
          label:'UnitCustomerPrice' 
        },
        {
          name: '付款金额',
          newValue: [],
          oldValue: [],
          label:'PayOrderMoney' 
        },
        // 先注释
        // {
        //   name: '访问-付款转化率',
        //   newValue: [],
        //   oldValue: [],
        //   label:'CustomerRate' 
        // }
      ],
      vipFansLegend:['新成交客户付款金额', '老成交客户付款金额'],
      vipFansXAxisData:['普通客户','微信粉丝','店铺会员'],
      vipFansBarData:{
        newValue: [0,0,0],
        oldValue: [0,0,0],
      },
      oldLineXAxis:[],
      oldLineData:[
        {
          name: '老成交客户趋势',
          value: [] 
        }
      ],
      oldStartTime:'',
      oldEndTime:'',
      listState: '1',
      listStateList: [
        {
          id: '1',
          value: '新访问留存'
        },
        {
          id: '2',
          value: '新成交留存'
        }
      ],
      tableList2:[],
      rePage:{
        size:20,
        total:0,
        current:1
      },
      viPage:{
        size:20,
        total:0,
        current:1
      },
      StartTime:'',
      EndTime:''
    }
  },
  methods:{
    toBreak(val) {
      return val.split('\n').join('<br>')
    },
    dealWeekRange(weekGroup) {
      this.groupDate = []
      let weekNums = []
      weekGroup.forEach(t => {
        var year = new Date(t).getFullYear()
        // 将传递的日期字符串变成时间戳
        var timeStamp = Date.parse(t);
        // 根据时间戳得到时间
        var dateTime = new Date(timeStamp);
        // 获取今年的第一天
        var newYear = new Date(dateTime.getFullYear().toString());
        // 计算今天是今年的第多少天
        var yearDay = Math.ceil((dateTime - newYear) / (24 * 60 * 60 * 1000)) + 1;
        // 获取1月1日的星期
        var oneDayAsWeek = newYear.getDay();
        if (oneDayAsWeek === 0) {
          // 星期日距离下星期一相差1天
          yearDay -= 1;
        } else if (oneDayAsWeek === 6) {
          // 星期六距离下星期一相差2天
          yearDay -= 2;
        } else if (oneDayAsWeek === 5) {
          // 星期五距离下星期一相差3天
          yearDay -= 3;
        } else if (oneDayAsWeek === 4) {
          // 星期四距离下星期一相差4天
          yearDay -= 4;
        } else if (oneDayAsWeek === 3) {
          // 星期三距离下星期一相差5天
          yearDay -= 5;
        } else if (oneDayAsWeek === 2) {
          // 星期二距离下星期一相差6天
          yearDay -= 6;
        }
        // 计算剩余的星期数
        var week = Math.ceil(yearDay / 7);
        // 如果当年第一天不是星期一，则需要手动加1周（原因是因为上面去掉了不完整星期的天）
        if (oneDayAsWeek != 1) {
          week++;
        }
        let y = year + '年第' + week + '周'
        weekNums.push(y)
      })
      this.oldLineXAxis = weekNums.reverse()
    },
    // 公用计算的方法
			commentDatafun(CurrentData, PrevData) {
        if(PrevData==0){
          if(CurrentData==0){
            return 0
          }else{
            return 100
          }
        } else {
				  return ( Math.abs((CurrentData - PrevData) / PrevData) * 100).toFixed(2)
        }
      },
      commentRateTrend(CurrentData, PrevData){
        return (Math.abs(CurrentData - PrevData)).toFixed(2)
      },
      // 公用占比计算的方法
			commentProportion(CurrentData, AmountData)  {
        if(AmountData==0){
          if(CurrentData==0){
            return 0
          }else{
            return 100
          }
        }else{
          return (Math.abs(CurrentData  / AmountData) * 100).toFixed(2)
        }
      },
      // 公用的箭头方法
			commentArrowsfun(CurrentData, PrevData) {
				let upOrDown = ""
				if (CurrentData != PrevData) {
					if (CurrentData > PrevData) {
						upOrDown='gorise'
					}else{
						upOrDown='decline'
					}
				} else {
					upOrDown = 'gorise'
				}
				return upOrDown
			},
    //初始化
			async getData() {
				try {
					let data = {
            TimeType: this.timeType,
            StartDate: this.startDate,
						StartTime: this.startLineTime,
						EndTime : this.endLineTime,
					}
          let result = await apiList.dataStatisCustomerOverview(data);
          this.customerData = result.Result
          this.customerData.NetIncrease = this.customerData.NewWeixinFansCount - this.customerData.RunawayWeixinFansCount 
          this.customerData.LastNetIncrease = this.customerData.LastNewWeixinFansCount - this.customerData.LastRunawayWeixinFansCount  
          
          let CustomerOverviewAndTrendList = this.customerData.CustomerOverviewAndTrendList
          this.trendLineData.forEach((t)=>{
            let item = []
              CustomerOverviewAndTrendList.forEach(tt=>{
                  Object.keys(tt).forEach(ttt=>{//遍历一个对象的属性
                      if(t.label == ttt){
                        item.push(tt[ttt])  
                      }
                  })
              })
              t.value = item
          })

          this.lineDateData = []

					if(this.timeType == 2){
						CustomerOverviewAndTrendList.forEach(t=>{
							let start = t.StatisStartDate.trim().split(" ")[0]
							let end = t.StatisEndDate.trim().split(" ")[0]
							let item = start +'~'+end
							this.lineDateData.push(item)
						})
					}

          let FansActiveList = this.customerData.FansActiveList
          this.fansLineData.forEach((t)=>{
            let item = []
              FansActiveList.forEach(tt=>{
                  Object.keys(tt).forEach(ttt=>{//遍历一个对象的属性
                      if(t.label == ttt){
                        item.push(tt[ttt])  
                      }
                  })
              })
              t.value = item
          })

          let VipActiveList = this.customerData.VipActiveList
          this.vipLineData.forEach((t)=>{
            let item = []
              VipActiveList.forEach(tt=>{
                  Object.keys(tt).forEach(ttt=>{
                      if(t.label == ttt){
                        item.push(tt[ttt])  
                      }
                  })
              })
              t.value = item
          })

          let data2 = {
            TimeType: this.timeType,
            StartDate: this.startDate,
						StartTime: this.startLineTime,
						EndTime : this.endLineTime,
            OldCustomerStartTime:this.oldStartTime,
            OldCustomerEndTime: this.oldEndTime
          }
          
          let result2 = await apiList.dataStatisTradingAnalyze(data2);
          this.tradingAnalyzeData = result2.Result

          let TradingCustomer = this.tradingAnalyzeData.TradingCustomerAnalyzeList
          let currentList = []
          let beforelist = []
          let time = ''

          if(TradingCustomer.length){
            time = TradingCustomer[0].StatisStartDate
          }

          TradingCustomer.forEach((t)=>{
            if(t.StatisStartDate == time){
              beforelist.push(t)
            }else{
              currentList.push(t)
            }
          })
          currentList.map((t)=>{
            beforelist.forEach((tt)=>{
              if(t.CustomerTypeValue == tt.CustomerTypeValue){
                  t.beforeCustomerRate= tt.CustomerRate
                  t.beforeCustomerType= tt.CustomerType
                  t.beforePayOrderMemberCount= tt.PayOrderMemberCount
                  t.beforePayOrderMoney= tt.PayOrderMoney
                  t.beforeUnitCustomerPrice= tt.UnitCustomerPrice
                  t.beforeUv= tt.Uv
              }
            })
          })
          this.tableList1 = currentList 
          //趋势指标选择
          this.trendRadioData.forEach((t)=>{
            let newItem = []
            let oldItem = []
            this.tradingAnalyzeData.TradingCustomerAnalyzeChartList.forEach(tt=>{
                  Object.keys(tt).forEach(ttt=>{
                      if(t.label == ttt){
                        if(tt.CustomerTypeValue == '新客户'){
                          newItem.push(tt[ttt])  
                        }else if(tt.CustomerTypeValue == '老客户'){
                          oldItem.push(tt[ttt])  
                        }else{// '全部'
                          newItem.push(tt[ttt])  
                          oldItem.push(tt[ttt])  
                        }
                      }
                  })
              })
              t.newValue = newItem
              t.oldValue = oldItem
          })

          this.filterBarData()

          //会员粉丝成交
          let VipList = this.tradingAnalyzeData.VipFansTradingList
          if(VipList.length){
            let echartDataNew = VipList[0]
            let echartDataOld = VipList[1]
            this.vipFansBarData ={
              newValue:[echartDataNew.CommomCustomerPayMoney,echartDataNew.WeixinFansPayMoney,echartDataNew.VipPayMoney],
              oldValue:[echartDataOld.CommomCustomerPayMoney,echartDataOld.WeixinFansPayMoney,echartDataOld.VipPayMoney]
            }
          }

          // 老成交客户趋势
          let OldList = this.tradingAnalyzeData.OldCustomerPayOverview
          if(OldList.length){
            OldList.forEach(t=>{
              this.oldLineData[0].value.push(t.Rate)
            })
          }else{
            this.oldLineXAxis.forEach(t=>{
              this.oldLineData[0].value.push(0)
            })
          }
				} catch (e) {
					console.log(e)
				}
      },
      filterBarData(){
          this.trendRadioData.forEach((t)=>{
            if(this.trendRadioVisit == t.label){
              this.trendBarData = {
                newValue:  t.newValue,
                oldValue:  t.oldValue
              }
            }
          })
      },
      async getTableData(){
        try{
          // TimeType (int): 时间范围 0-最近1个月 1-最近3个月 2-最近6个月 3-最近1年,
          let TimeType = this.timeType2==7?0:(this.timeType2==8?1:2)
          let data = {
            TimeType: this.TimeType,
            RetentionType: this.listState
          }
          let result = await apiList.dataStatisCustomerRetention(data)
          this.tableList2 = result.Result

          this.tableList2.map((t)=>{
            t.StatisDate = t.StatisDate.trim().split(" ")[0]
          })
        } catch (e){
        }
      },
    // 获取本周的周一和周末的日期
    getWeekDate(date,rangeWeeks) {
      var now = new Date(date);
      var nowTime = now.getTime();
      var day = now.getDay();
      var oneDayTime = 24 * 60 * 60 * 1000;
      var MondayTime = nowTime - (day - 1) * oneDayTime; //显示周一
      var SundayTime = nowTime + (7 - day) * oneDayTime; //显示周日
      var newSundayTime = this.formatDate(new Date(SundayTime))
      //echart显示最近4周的信息，rangeWeeks*7-1
      let range = rangeWeeks*7-1
      this.oldEndTime = newSundayTime
      this.oldStartTime = this.formatDate(new Date(new Date(SundayTime) - 1000 * 60 * 60 * 24 * range ))

      // weekGroup获取每一周的周日
      let weekGroup = []
      for (let i = 0; i < rangeWeeks; i++) {
        let oneWeek = this.formatDate(new Date(new Date(SundayTime) - 1000 * 60 * 60 * 24 * 7 * i))
        weekGroup.push(oneWeek)
      }
      this.dealWeekRange(weekGroup)
    },
    
    formatTen(num) {
      return num > 9 ? (num + "") : ("0" + num);
    },
    //格式化时间 YYYY-MM-DD/ YYYY-MM-DD hh:mm:ss
    formatDate(date) {
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var h = date.getHours();
      //获取当前分钟数(0-59)
      var m = date.getMinutes();
      //获取当前秒数(0-59)
      var s = date.getSeconds();
      if(this.timeType == 4){
        return year + "-" + this.formatTen(month) + "-" + this.formatTen(day) + " " + this.formatTen(h) + ':' + this.formatTen(m) + ":" + this.formatTen(s);
      }
      else{
        return year + "-" + this.formatTen(month) + "-" + this.formatTen(day);
      }
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      let count = 0
      if (columnIndex == 0||columnIndex == 1) {
      return;
      } else if (columnIndex == 2){
        if(row.One==0){
          return ;
        }else{
          count = (row.One)/10
          return "colorcal" + parseInt(count);
        }
      } else if (columnIndex == 3){
        if(row.two==0){
          return;
        }else{
          count = (row.two)/10
          return "colorcal" + parseInt(count);
        }
      } else if (columnIndex == 4){
        if(row.three==0){
          return;
        }else{
          count = (row.three)/10
          return "colorcal" + parseInt(count);
        }
      } else if (columnIndex == 5){
        if(row.four==0){
          return;
        }else{
          count = (row.four)/10
          return "colorcal" + parseInt(count);
        }
      } else if (columnIndex == 6){
        if(row.five==0){
          return;
        }else{
          count = (row.five)/10
          return "colorcal" + parseInt(count);
        }
      }
    },
    getRangeTime(startDate,endDate,timeType,startLineTime,endLineTime,groupDate){
      this.fansLineXAxis = this.trendLineXAxis = this.vipLineXAxis = this.trendXAxisData = this.oldLineXAxis = groupDate
      this.startDate = startDate
      this.endDate = endDate
      this.startLineTime = startLineTime
      this.endLineTime = endLineTime
      this.timeType = timeType
      this.currentKeyword =this.timeKeywords[timeType-1]
      
      //一周前
      let Weekvalue = this.formatDate(new Date(new Date() - 1000 * 60 * 60 * 24 * 7 ))
      if(timeType==1||timeType==4||timeType==5){
        this.getWeekDate(Weekvalue,4)
      } else if(timeType==2){
        this.getWeekDate(Weekvalue,12)
      } else if(timeType==3){
        this.getWeekDate(Weekvalue,30)
      }
      this.getData()
    },
    
    // 获取老成交客户的开始时间和结束时间
    getOldCustomerTime(){},
    getRangeTime2(startDate,endDate,timeType,startLineTime,endLineTime){
      this.startDate2 = startDate
      this.endDate2 = endDate
      this.timeType2 = timeType
      this.getTableData()
    },
    handleTabClick(e) {
      this.getTableData()
    },
    //获取周，折线图日期显示，上月第一周的周一至上月最后一周的周日
    getLastMonth(){
      var nowdays = new Date();  
      var year = nowdays.getFullYear();  
      var month = nowdays.getMonth();  
      if(month==0)  
      {  
          month=12;  
          year=year-1;  
      }  
      if (month < 10) {  
          month = "0" + month;  
      }  
      let firstDay = new Date(year + "-" + month + "-" + "01");//上个月的第一天  
      let lastDate =new Date(year, month, 0)  //上个月最后一天
      this.getMonSun(firstDay,1)
      this.getMonSun(lastDate,0)
    },
    // 获取time此日期所在周的周一或者周日
    getMonSun(time,index){
      let nowTime = time.getTime();
      let day = time.getDay();
      let oneDayTime = 24 * 60 * 60 * 1000;
      if(index==1){
        //获取周一
        let MondayTime = nowTime - (day - 1) * oneDayTime; 
        this.oldStartTime = this.formatDate(new Date(MondayTime))
      }else{
        //获取周日
        let SundayTime = nowTime + (7 - day) * oneDayTime; //显示周日
        this.oldEndTime = this.formatDate(new Date(SundayTime))
      }
    },
    handleTrendRadio(e){
      this.filterBarData()
    },
    
    handleSizeChange(val){
      this.page.size = val
      this.getTableData()
    },
    handleCurrentChange(val){
      this.page.current = val
      this.getTableData()
    },
    handleViSizeChange(val){
      this.viPage.size = val
      this.getTableData()
    },
    handleViCurrentChange(val){
      this.viPage.current = val
      this.getTableData()
    },
    handleReSizeChange(val){
      this.rePage.size = val
      this.getTableData()
    },
    handleReCurrentChange(val){
      this.rePage.current = val
      this.getTableData()
    }
  }
}
</script>
<style lang="less" scoped>
.main-container{
  .module{
    margin-top:10px;
    padding:15px;
    background-color: #fff;
    margin:10px 0;
  }
  .title{
    font-size: 20px;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    .right{
      float: right;
      margin-right: 5px;
    }
  }

  .secondary-title{
    padding: 10px 0;
    margin:5px 0;
  }
  
  .interview{
    background-color: rgb(225, 238, 251);
    height: 100%;
    color:#606266;
    width: 100%;
    align-items: center;
    .item{
      padding: 25px;
      display: inline-block;
      width: 25%;
    }
    .item:nth-child(5n){
      word-wrap: break-word;
      word-break: normal;
    }
    .lable{
      line-height: 20px;
      font-size: 12px;
    }
    .icon{
      font-size: 14px;
      color:#999;
      margin-left:5px;
      cursor: pointer;
    }
    .num{
      line-height: 30px;
      font-size: 18px;
    }
    .compare{
      line-height: 20px;
      font-size: 12px;
    }
    .compare-hidden{
      display: none;
    }
  }
  .echart-box{
    padding:10px;
    width: 100%;
    height: 300px;
    margin-top: 10px;
    background-color: #fff;
  }
  .icon{
    color:#999;
    margin-left:5px;
    cursor: pointer;
  }
  .retention{
    padding:0 15px 15px 15px;
    .title{
      // padding-top:10px;
    }
  }
  .closed-customer{
    .table{
      overflow: hidden;
      position: relative;
    }
    .customer-trend{
      background-color: #eee;
      padding: 15px;
      margin: 10px 0;
    }
  }


  ::v-deep .colorcal0 {
    background: rgba(0,110,221, 0.1)!important;
    color: #000;
  }

  ::v-deep .colorcal1 {
    background: rgba(0,110,221, 0.2)!important;
    color: #000;
  }

  ::v-deep .colorcal2 {
    background: rgba(0,110,221,0.3) !important;
    color: #000;
  }

  ::v-deep .colorcal3 {
    background: rgba(0,110,221,0.4) !important;
    color: #000;
  }

  ::v-deep .colorcal4 {
    background: rgba(0,110,221,0.5) !important;
    color: #000;
  }

  ::v-deep .colorcal5 {
    background: rgba(0,110,221,0.6) !important;
    color: #000;
  }

  ::v-deep .colorcal6 {
    background: rgba(0,110,221,0.7) !important;
    color: #000;
  }

  ::v-deep .colorcal7 {
    background: rgba(0,110,221,0.8) !important;
    color: #000;
  }

  ::v-deep .colorcal8 {
    background: rgba(0,110,221,0.9) !important;
    color: #000;
  }

  ::v-deep .colorcal9 {
    background: rgba(0,110,221,1) !important;
    color: #000;
  }
}
</style>